<template>
  <div class="table">
    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center">
        <span>备课数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-row style="margin-top: 20px">
        <el-col :span="6">
          <el-card>
            <el-tree
              :data="treeList"
              highlight-current
              :props="defaultProps"
              @node-click="handleNodeClick"
              style="padding-top: 20px; padding-bottom: 20px"
              default-expand-all
              :default-checked-keys="deId"
              :current-node-key="currentId"
              node-key="id"
              ref="tree"
            ></el-tree>
          </el-card>
        </el-col>
        <el-col :span="16" :offset="1">
          <el-card style="margin-bottom: 20px">
            <el-table-bar fixed :static="true">
              <el-table
                :key="tableKey"
                :data="dataList"
                fit
                :highlight-current-row="true"
              >
                <el-table-column type="index" width="50" label="课次" :index="indexMethod"> </el-table-column>
                <el-table-column
                  v-for="item of defaultHeader"
                  :key="item.label"
                  :label="item.label"
                  :align="item.align ? item.align : 'left'"
                  :min-width="item.width ? item.width : 'auto'"
                  :prop="item.prop"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    <template v-if="item.label === '课节名称'">
                      <span v-if="scope.row.title">
                        {{ scope.row.title }}
                      </span>
                      <span v-else>--</span>
                    </template>
                    <!-- <template v-else-if="item.label === '状态'">
                      <span
                        class="span-state"
                        :style="
                          'backgroundColor' +
                          ':' +
                          getPreparColor(scope.row.prepare)
                        "
                      ></span>
                      <span
                        v-text="getPreparColorText(scope.row.status)"
                      >
                      </span>
                    </template> -->
                    <template v-else-if="item.label === '课时'">
                      <span v-if="scope.row.comsumeAmount">
                        {{ scope.row.comsumeAmount }}
                      </span>
                      <span v-else>--</span>
                    </template>
                    <template v-else-if="item.label === '操作'">
                      <el-button
                        type="text"
                        @click="prepareLessonDetail(scope.row)"
                        >开放备课</el-button
                      >
                    </template>
                    <span v-else>{{ scope.row[item.prop] }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-table-bar>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.currentPage"
              :page-sizes="pagination.pageSizes"
              :page-size="pagination.pageSize"
              :layout="pagination.layout"
              :total="pagination.total"
            ></el-pagination>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <!-- title="商户课程" -->

    <AddModal
      ref="add"
      type="primary"
      :courseUnitId="courseUnitId"
    ></AddModal>
  </div>
</template>

<script>
import TextButton from "@/components/TextButton";
import getPreparColor from "@/mixins/getStateColor";
import getPreparColorText from "@/mixins/getStateColor";
import AddModal from "@/views/member/merchantdata";

import {
  $getPreparationCourseListByUser,
  $courseUnitList,
  $getMerchantList,
  $addCoursePreparationUser,
} from "@/apiteacher/teacher";
// 默认展示列
const baseColSetting = [
  {
    label: "课节名称",
    prop: "title",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "状态",
    prop: "status",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "课时",
    prop: "comsumeAmount",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: { TextButton, AddModal },

  mixins: [getPreparColor, getPreparColorText],
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  mounted() {
    this.getPreparationCourseListByUser();
  },
  watch: {
    deId: {
      handler(val) {
        console.log(val);
        this.deId = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      id: "",
      deId: [],
      treeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      dataList: [],
      tableKey: 1,
      currentId: "", // 当前选中的节点(移动/复制到的目标文件夹id)
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      courseUnitId:''
    };
  },
  methods: {
    indexMethod(index){
      return (this.pagination.currentPage - 1) * this.pagination.pageSize + index + 1;
    },
    handleNodeClick(data) {
      if (data.parentId !== 0) {
        this.currentId = data.id;
        this.courseUnitList();
      }
    },
    // 清空选项
    // clearQuery () {
    //   this.queryParam = {
    //     status: '',
    //     name:''
    //   }
    // },

    search() {
      this.pagination.currentPage = 1;
      this.courseUnitList();
    },

    // 获取备课课程列表
    async getPreparationCourseListByUser() {
      const res = await $getPreparationCourseListByUser({});
      this.treeList = res.body;
      if (this.treeList.length !== 0) {
        this.currentId = this.treeList[0].children[0].id;
        this.deId = [this.treeList[0].children[0].id];
        this.courseUnitList();
        this.$nextTick(() => {
          // this.$refs.tree.setCurrentKey(this.treeList[0].children[0].id);
        });
      }
    },

    // 获取全部课节列表
    async courseUnitList() {
      this.dataList = [];
      const res = await $courseUnitList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        courseId: this.currentId,
      });
      this.dataList = res.body.list;
      this.pagination.total = res.body.total;
    },
    /**
     * 商户数据老师数据
     * */
    prepareLessonDetail(row) {
      this.courseUnitId = row.id;
      this.$refs.add.dialogVisible = true;
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.courseUnitList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.courseUnitList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/container.scss";
@import "../../style/table.scss";
/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
.is-current {
  background-color: #eee;
}
</style>
